@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@keyframes vibrate {0%,100% {transform: scale(1);}50% {transform: scale(1.05);}}
@keyframes flipUp {0% {transform: scale(0) translate(-50%,0%);transform-origin: 0% 50%;}100% {transform: scale(1) translate(-50%,0%);transform-origin: 0% 50%;}}
:root{--bg:#fff;--fg:#000;;--color1: #CC7727;--color2:#472E26;--bg2:#FFFD;--bg3:#0004;--color3:#2e75f1;--invert:invert(1)}
.n{user-select:none;-moz-user-select:none;-khtml-user-select:none;-webkit-user-select:none;-o-user-select:none}
body{background:var(--bg)!important;color:var(--fg)!important;box-sizing: border-box;overflow-x: hidden;}
*{font-family: Rubik;box-sizing: border-box;font-weight: normal;}
input[type="radio"] {appearance: none;background-color: #fff;width: 20px;height: 20px;border: 5px solid #555;border-radius: 40px;transition: .3s;}
input[type="radio"]:checked {appearance: none;background-color: #FFF;width: 20px;height: 20px;border: 5px solid var(--color1);border-radius: 40px;transition: .3s;animation: vibrate .5s;}
nav{position: fixed;display: flex;align-items: center;justify-content: space-between;padding: 10px 30px 10px 5px;background: var(--bg);height: 80px;width:100%;z-index: 5;}
nav::before{content:'';width: calc(55vw + 50px);height: 80px;position: absolute;background: var(--color1);right: -20px;transform: skewX(20deg);z-index: -1;}
nav::after{content:'';width: 55vw;height: 80px;position: absolute;background: var(--color2);right: -20px;transform: skewX(20deg);z-index: -1;}
nav .logo{display: flex;align-items: center;justify-content: center;cursor: pointer;}
nav .logo img{height: 80px;width: auto;}
nav .headings{display: flex;align-items: center;justify-content: center;}
nav .headings ul {display: flex;align-items: center;}
nav .headings ul a{margin: 0 10px;padding: 7.5px 20px;border-radius: 5px;cursor: pointer;color: #FFF;transition: .3s;}
nav .headings ul a:nth-child(2){display: flex;align-items: center;justify-content: space-between;padding: 7px 10px;}
nav .headings ul a.active:nth-child(2){display: flex;align-items: center;justify-content: space-between;padding: 7px 10px;}
nav .headings ul a i{margin-left: 15px;}
nav .headings ul a:hover{background: var(--color1);color: #FFF;border-radius: 5px;transition: .3s;}
nav .headings ul a.active{border-bottom: var(--color1) 3px solid;padding: 7px 20px;border-radius: 0px;transition: .3s;}
nav .headings ul a.active:hover{color: var(--color1);background: none;transition: .3s;}
nav .menuBar{transition: .5s ease-out right;display: none;z-index: 3;position: absolute;top: 80px;right: -100%;height: calc(100vh - 80px);background: var(--bg);width: 60vw;flex-direction: column;justify-content: start;align-items: center;padding: 80px 20px;box-shadow: -10px 20px 20px #0009;overflow-x: hidden;overflow-y: scroll;}
nav .menuToggler{border-radius: 5px;padding: 5px 10px;display: none;cursor: pointer;}
nav .menuToggler i{font-size: 28px;}
nav .menuBar .logo{margin-bottom: 20px;}
nav .menuBar .logo img{width: 250px;height: auto;cursor: pointer;}
nav .menuBar .links{width: 100%;}
nav .menuBar .links p#this{padding: 16px 20px;width: 100%;cursor: pointer;}
nav .menuBar .links .collapse.show,nav .menuBar .links .collapsing{display: flex;flex-direction: column;}
nav .menuBar .links .collapse.show a:not(.x),nav .menuBar .links .collapsing a:not(.x){padding: 16px 30px;width: 100%;cursor: pointer;}
nav .menuBar .links .separator{width: 95%;height: 2px; background:var(--color1);margin: 5px auto;}
nav .menuBar .links .separator.mini{width: 80%;margin: 5px auto;height: 1px;}
nav.second .headings ul a {margin: 0 5px;padding: 7.5px 10px;}
nav .menuBar .links{width: 95%;}
nav .menuBar .links .linkRow{display: flex;align-items: center;width: 100%;margin: 20px 0;}
nav .menuBar .links .linkRow a{font-size: 20px;text-align: center;border: 2px solid var(--color1);margin: 0 10px;padding: 15px 20px;border-radius: 5px;transition: 1s;cursor: pointer;color: var(--fg);width: 50%;box-shadow: 3px 3px 5px var(--color5);}
nav .menuBar .links .linkRow.expanded a{width: 100%;}
.accordion-button{border: none!important;outline: none;}
footer{background:var(--color2);box-shadow:0 1px 1px 0 rgba(0,0,0,.12);box-sizing:border-box;width:100%;text-align:left;font:bold 16px sans-serif;padding:55px 50px;}
footer .footer-center,footer .footer-left,footer .footer-right{display:inline-block;vertical-align:top}
footer .footer-left{width:35%}
footer img{width: 300px;background: #FFF;border-radius: 15px;padding: 10px;}
footer .footer-links{color:#fff;margin:20px 0 12px;padding:0}
footer .footer-links a{display:inline-block;line-height:1.8;font-weight:400;text-decoration:none;color:inherit}
footer .footer-company-name{color:#ddd;font-size:14px;font-weight:400;margin:0}
footer .footer-center{width:35%}
footer .footer-center .iconContainer{display: grid;place-items:center;background-color:var(--color1);color:#fff;font-size:20px;width:38px;height:38px;border-radius:50%;text-align:center;line-height:42px;margin:10px 15px;}
footer .footer-center .infoContainer{display:flex;align-items: center;}
footer .footer-center p{display:inline-block;color:#fff;font-weight:400;margin:0}
footer .footer-center p span{display:block;font-weight:400;font-size:14px;line-height:2}
footer .footer-center p a{color:#20b2aa;text-decoration:none}
footer .footer-links a:before{content:" -";font-weight:300;font-size:20px;left:0;color:#fff;display:inline-block;padding-right:5px}
footer .footer-links .link-1:before{content:none}
footer .footer-right{width:30%}
footer .footer-company-about{line-height:20px;color:#92999f;font-size:13px;font-weight:400;margin:0}
footer .footer-company-about span{display:block;color:#fff;font-size:14px;font-weight:700;margin-bottom:20px}
footer .language{display: flex;margin-top: 20px;}
footer .language .lng{display: flex;align-items: center;padding: 10px;border-radius: 10px;margin: 10px;cursor: pointer;transition: .25s all ease-in-out;}
footer .language .lng.active{background: var(--color1);transition: .25s all ease-in-out;}
footer .language .lng img{width: 30px;margin-right:20px;}
footer .language .lng p{color: #FFF;}
.minBttn,.bttn{display:flex;align-items: center;justify-content: center;background: var(--color1);color: #FFF;cursor: pointer;transform: scale(1);transition: .3s!important;outline: none;border: none;}
.bttn{border-radius: 100px;height: 50px;padding: 10px 20px;}
.bttn:hover{box-shadow: 0px 0px 20px var(--color1);transition: .3s;color: #FFF;}
.bttn h4{margin-right: 10px!important;font-weight: 600;font-size: 18px;}
.bttn.expanded{width: 100%;}
.bttn.secondColor:hover{box-shadow: 0px 0px 20px var(--color2);}
.minBttn{border-radius: 5px;padding: 5px 10px;}
.minBttn:hover{box-shadow: 0px 0px 10px var(--color1);transition: .3s;}
.minBttn h4,.bttn h4{font-family: Rubik;margin: 0;}
.minBttn h4{font-weight: 400;font-size: 18px;}
.minBttn.outlined,.bttn.outlined{border: 2px solid var(--color1);background: none;color: var(--fg);transition: .3s;}
.minBttn.outlined:hover,.bttn.outlined:hover{box-shadow: 0px 0px 10px var(--color1),inset 0px 0px 5px var(--color1);transition: .3s;}
.minBttn.secondColor,.bttn.secondColor{background: var(--color2);}
.minBttn.secondColor:hover{box-shadow: 0px 0px 10px var(--color2);}
.serviceCard{transition: 1s!important;margin: 10px 20px;width: 100%;height: 250px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;border-radius: 30px;display: flex;flex-direction: column;align-items: stretch;padding: 10px;background: var(--color2);justify-content: space-evenly;max-width: 550px;}
.serviceCard .cardIcon{height: 40%;padding: 0 20px;display: flex;align-items: center;justify-content: space-around;}
.serviceCard .cardIcon .iconN{display: grid;place-items: center;width: 90px;height: 90px;border-radius: 90px;position: relative;transition: .5s ease-in-out;background: #FFF;}
.serviceCard .cardIcon .iconN i{transition: .5s;font-size: 35px;color: #FFF;color: var(--color1);}
.serviceCard .cardIcon h3{font-weight: 700;text-align: center;color: #FFF;width: 50%;}
.serviceCard .separator{height: 1px;background: #ddd;margin: 10px 30px;}
.serviceCard .contentX{padding: 10px;transition: .5s;display: block!important;color: #FFF;}
.serviceCard .contentX p{text-align: justify;padding: 0 10px;font-size: 16px;line-height: 20px;}
.serviceCard:hover{transition: .3s!important;background: var(--color1);}
.serviceCard:hover .cardIcon .iconN{transition: .5s;background: var(--color1);}
.serviceCard:hover .cardIcon .iconN i{transition: .5s;font-size: 45px;color: #FFF;}
.topBanner{width: 100%;height: 100vh;overflow: hidden;position: relative;background-attachment: fixed;background-repeat: no-repeat;background-size: cover;background-position: center;}
.topBanner img{width: 100vw;}
.topBanner .cHeading{width: 100%;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50px);z-index: 1;display: flex;flex-direction: column;align-items: center;background: #472E26AA;padding: 50px 0;}
.topBanner .cHeading h1{font-size: 3.5vw;color: #FFF;font-weight: 700;text-align: center;}
.topBanner .cHeading a{animation: 1s infinite ease-in-out vibrate;position: absolute;bottom: -100px;}
.sectionTitle{width: 100%;text-align: center;}
.sectionTitle h1{font-weight: 600;}
.sectionTitle h1 span{font-weight: 800;color: var(--color1);}
.sectionTitle .decoration{width: 100%;display: flex;align-items: center;justify-content: center;margin-top:15px;}
.sectionTitle .decoration .x{width: 30px;height: 30px;background: var(--color1);border-radius: 40px;}
.sectionTitle .decoration::before,.sectionTitle .decoration::after{content:'';width:20%;height: 2px;background: var(--color1);margin-inline:10px;}
.whoAreWe{padding: 20vh 0;position: relative;}
.whoAreWe .anchor{position: absolute;top: 0;left: 0;}
.whoAreWe .content{display: flex;align-items: stretch;margin-top: 20px;height: 65vh;}
.whoAreWe .content .thumb{width: 50%;display: grid;place-items: center;position: relative;}
.whoAreWe .content .thumb img{width: 80%;box-shadow:var(--color1) -20px -20px 0px,rgba(0, 0, 0, 0.4) 0px 15px 45px;}
.whoAreWe .content .thumb .pattern{width: 50px;height: 50px;border-radius: 50px;position: absolute;bottom: 7%;right: 7%;border: var(--color1) 10px solid;}
.whoAreWe .content .desc{width: 50%;padding-right: 3%;display: flex;flex-direction: column;justify-content: center;}
.whoAreWe .content .desc p{font-size: 18px;line-height: 25px;text-align: justify;}
.whoAreWe .content .desc p span{font-weight: 600;}
.whoAreWe .content .desc p:first-child {margin-bottom: 18px;}
.whoAreWe .content .desc ul{list-style-type: disc; margin: 20px 0 0 20px;}
.whoAreWe .content .desc ul li{font-size: 18px;line-height: 30px;color: #000;}
.whoAreWe .content .desc ul li::marker{color: var(--color1)}
.buttonCenterX{width: 100%;display: flex;align-items: center;margin-top: 100px;justify-content: space-evenly;}
.buttonCenterX a{scale: 1.3;}
.buttonCenterX a:first-child{flex-direction: row-reverse;background: #262562;}
.buttonCenterX a:first-child:hover{box-shadow: #262562 0px 0px 20px;}
.buttonCenterX a:first-child h4{margin-left: 10px;margin-right: 0;}
.buttonCenterX.Y a:first-child{flex-direction: row-reverse;background: #CC7727;}
.buttonCenterX.Y a:first-child:hover{box-shadow: #CC7727 0px 0px 20px;}
.buttonCenterX.Y a:first-child h4{margin-left: 10px;margin-right: 0;}
.advice{padding: 20vh 0;}
.advice .content .desc1{font-size: 25px;text-align: center;width: 80%;margin: 50px auto;line-height: 35px;}
.advice .content .desc1 p span{color: var(--color1);font-weight: 600;}
.advice .content .desc2{display: flex;align-items: center;justify-content: space-evenly;width: 100%;}
.advice .content .desc2 .serviceCard:nth-child(2) .cardIcon h3{width: 65%}
.activities{padding: 20vh 0;}
.activities .content {margin: 30px 10px;}
.activities .content .titleNum{display: flex;align-items: center;margin: 30px 0;}
.activities .content .titleNum .num{display: grid;place-items: center;width: 60px;height: 60px;border-radius: 80px;background: var(--color1);color: #FFF;margin:5px  20px;}
.activities .content .titleNum h1{color: var(--color1);position: relative;}
.activities .content .desc {width: calc(100% - 90px);margin-left:50px;border-left: 2px solid var(--color1);padding:0 40px;}
.activities .content .desc p{font-size: 18px;line-height: 35px;text-align: justify;}
.activities .content .desc p span{color: var(--color1);font-weight: 600;}
.activities .content .desc h1{color: var(--color1);width: 100%;padding-bottom: 10px;border-bottom: 2px solid var(--color1);margin: 30px 0;position: relative;text-align: center;}
.activities .content .desc h2{color: var(--color1);width: 100%;margin: 10px 0;position: relative;}
.activities h1 .anchor,.activities h2 .anchor{position: absolute;top: -100px;left: 0;}
.activities .content .desc .illu{display: flex;height: 35vh;margin: 20px 0;justify-content: space-evenly;flex-wrap: wrap;}
.activities .content .desc .illu .thun{height: 100%;position: relative;}
.activities .content .desc .illu .thun::after{content:'';width: 40px;height: 40px;border-radius: 50px;position: absolute;border: var(--color1) 8px solid;bottom: -20px;left: -20px;}
.activities .content .desc .illu .thun img{height: 100%;border-radius: 20px;box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px, var(--color2) -10px 10px 0;}
.values{padding: 20vh 0;}
.values .content{width: 100%;padding: 50px 20px 40px 20px;display: flex;align-items: center;flex-wrap: wrap;justify-content: space-evenly;}
.values .content .lilCard{transition:.3s;width: 48%;height: 320px;display: flex;justify-content: center;flex-direction: column;margin: 30px 0px;border: .01px solid var(--color2);padding: 30px 20px;border-radius: 15px;box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;}
.values .content .lilCard .topCard{display: flex;align-items: center;width: 100%;margin-bottom: 30px;}
.values .content .lilCard p{transition:.3s;;font-size: 18px;line-height: 25px;text-align: justify;}
.values .content .lilCard .iconN{transition:1s;background: var(--color2);border-radius: 80px;width: 80px;height: 80px;display: grid;place-items: center;color: #FFF;}
.values .content .lilCard .iconN i{font-size: 40px;transition: .3s;}
.values .content .lilCard h1{font-size: 36px;color: var(--color2);text-align: center;width: 90%;transition: 1s;}
.values .content .lilCard:hover{border:.01px solid var(--color1);background: var(--color1);}
.values .content .lilCard:hover h1{transition:.3s;color: #FFF;}
.values .content .lilCard:hover p{transition:.3s;color: #FFF;}
.values .content .lilCard:hover .pattern{transition:.3s;color: #FFF;}
.values .content .lilCard:hover .iconN{transition:.3s;background:#fff;}
.values .content .lilCard:hover .iconN i{transition:.3s;color: var(--color1);}
#home1{height: 100vh;width: 100%;position: relative;overflow: hidden;}
#home1 .bg{width: 100%;height: 100%;display: grid;place-items: center;overflow: hidden;}
#home1 .bg img{width: 100%;}
#home1 .content{position: absolute;top: 50%;left: 80px;transform: translateY(-50%);z-index: 2;width: 50%;}
#home1 .content h1{font-size:50px;font-weight: 600;margin-bottom: 30px;color: #FFF;}
#home1 .content h1 span{font-size: 50px;font-family: Bitter;color: var(--color1);font-weight: 800;}
#home1 .content a{width: 150px;}
.sectionTitle1{text-align: center;width: 50%;position: relative;margin: 80px auto;padding-bottom: 20px;border-bottom: 2px solid var(--color1);}
.sectionTitle1::after,
.sectionTitle1::before{content:'';position: absolute;background: var(--color1);width: 40px;height: 40px;top: 50%;transform: translateY(-70%);z-index: 1;border-radius: 50px;}
.sectionTitle1::before{left: 0;}
.sectionTitle1::after{right: 0;}
#study{width: 100%;}
#study .content {margin: 30px 10px;}
#study .content .titleNum{display: flex;align-items: center;margin: 30px 0;}
#study .content .titleNum .num{display: grid;place-items: center;width: 60px;height: 60px;border-radius: 80px;background: var(--color1);color: #FFF;margin:5px  20px;}
#study .content .titleNum h1{color: var(--color1);position: relative;}
#study .content .titleNum h1 p{font-size: 18px;}
#study .content .desc {width: calc(100% - 90px);margin-left:50px;border-left: 2px solid var(--color1);padding:0 40px;}
#study .content .desc p{font-size: 18px;line-height: 35px;text-align: justify;}
#study .content .desc p span{color: var(--color1);font-weight: 600;}
#study .content .desc h1{color: var(--color1);width: 100%;padding-bottom: 10px;border-bottom: 2px solid var(--color1);margin: 30px 0;position: relative;text-align: center;}
#study .content .desc h2{color: var(--color1);width: 100%;margin: 10px 0;position: relative;}
#study h1 .anchor,.activities h2 .anchor{position: absolute;top: -100px;left: 0;}
#study .content .desc .illu{display: flex;height: 35vh;margin: 20px 0;justify-content: space-evenly;flex-wrap: wrap;}
#study .content .desc .illu .thun{height: 100%;position: relative;}
#study .content .desc .illu .thun::after{content:'';width: 40px;height: 40px;border-radius: 50px;position: absolute;border: var(--color1) 8px solid;bottom: -20px;left: -20px;}
#study .content .desc .illu .thun img{height: 100%;border-radius: 20px;box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px, var(--color2) -10px 10px 0;}
#opc .content{display: flex;align-items: center;}
#opc .content .thumb,#opc .content .desc{width: 50%;display: grid;place-items: center;}
#opc .content .thumb img{width: 80%;}
#opc .content .desc ul{list-style-type: disc;}
#opc .content .desc ul li{font-size: 28px;margin:10px 0;}
#opc .content .desc ul li:nth-child(odd)::marker{color: var(--color1);}
#opc .content .desc ul li:nth-child(even)::marker{color: var(--color2);}
.contact{width: 100%;position: relative;height: calc(100vh + 100px);}
.contact h1{text-align: center;padding-top: 17.5vh;color:#FFF;font-weight: 800;}
.contact p{text-align: center;color:#FFF;width: 60%;margin: 20px auto 35px auto;font-size: 18px;line-height: 25px;}
.contact::before{content: '';width: 100%;height: calc(50vh + 50px);position: absolute;z-index: -1;}
.contact::before{background: var(--color1);}
.contact .screen{width: 80vw;height: 55vh;border-radius: 30px;box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;position: absolute;left: 50%;transform: translate(-50%,0%);background: #FFF;display: flex;overflow: hidden;animation: flipUp 1s cubic-bezier(.46,.46,.44,1.26);}
.contact .screen .decor{width: 50%;height: 100%;overflow: hidden;}
.contact .screen .decor img{height: 100%;}
.contact .screen .content{width: 50%;display: flex;flex-direction: column;}
.contact .screen .content .topC{display: grid;place-items: center;background: var(--color2);height: 40%;}
.contact .screen .content .topC h2{color: #FFF;font-size: 2.2vw;}
.contact .screen .content .bottomC{display: flex;flex-direction: column;height: 60%;justify-content: space-evenly;align-items: center;}
.contact .screen .content .bottomC .cRow{display: flex;align-items: center;width: 90%; border-radius: 15px;height: 50px;padding: 0 20px;position: relative;cursor: pointer;}
.contact .screen .content .bottomC .cRow::after{position: absolute;background: var(--color2);border-radius: 30px;width: 50px;height: 50px;top: 0;left: 10px;content: '';z-index: 0;transition: 1s;}
.contact .screen .content .bottomC .cRow:hover::after{width: 100%;transition: .7s;}
.contact .screen .content .bottomC .cRow:not(:first-child)::after{left: 8px;}
.contact .screen .content .bottomC .cRow .iconN{display: grid;place-items: center;color: #FFF;position: relative;}
.contact .screen .content .bottomC .cRow:first-child .iconN i{font-size: 35px;z-index: 1;}
.contact .screen .content .bottomC .cRow:not(:first-child) .iconN i{font-size: 25px;z-index: 1;}
.contact .screen .content .bottomC .cRow h3{font-size: 1.75vw;margin-left: 30px;z-index: 1;transition: 1.5s;color: #000;}
.contact .screen .content .bottomC .cRow:hover h3{color:#FFF;transition: .3s;}
.devoo{width: 100vw;height: 100vh;display: grid;place-items: center;font-size: 45px;font-weight: 600;}
.whyWe{position: relative;margin-top: 80px;}
.whyWe h1{text-align: center;width: 80%;margin: 0 auto;padding-bottom: 20px;border-bottom: 3px solid var(--color2);color: var(--color2);}
.whyWe .content{display: flex;align-items: center;justify-content: space-evenly;margin: 30px 0;}
.whyWe .content img{height: 50vh;border-radius: 20px;box-shadow: -10px 10px 0 var(--color1);}
.whyWe .content p{font-size: 18px;line-height: 25px;width: 45%;text-align: justify;}
.whyWe .anchor{position: absolute;top: -150px;left: 0;}
#qs .content{display: flex;position: relative;}
#qs #qsCarousel{width: 80%;margin: 0 auto;}
#qs .content p{line-height: 25px;font-size: 18px;text-align: justify;}
#qs .content i{color: #FFF;font-size: 25px;z-index: 2;background: var(--color2);width: 50px;height: 50px;border-radius: 50px;display: grid;place-items: center;}
#qs .qsS{height: 400px;}
#qs .qsS h3{text-align: center;color: var(--color2);border-bottom: 2px solid var(--color2);padding-bottom: 20px;margin-bottom: 20px;}
.carousel-control-prev,.carousel-control-next{width: 10%;}
.lilCard2{background: #fff;width: 90%;height: auto;display: flex;justify-content: center;flex-direction: column;margin: 30px auto;border: .01px solid var(--color2);padding: 30px 20px;border-radius: 15px;box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;transition:.3s!important;}
.lilCard2 .topCard{display: flex;align-items: center;width: 100%;margin-bottom: 30px;}
.lilCard2 .topCard h3{margin-left: 25px;transition: .3s;}
.lilCard2 p{transition:.3s;font-size: 18px;line-height: 25px;text-align: justify;}
.lilCard2 .iconN{transition:1s;background: var(--color2);border-radius: 60px;width: 60px;height: 60px;display: grid;place-items: center;color: #FFF;}
.lilCard2 .iconN i{font-size: 30px;}
.lilCard2 h1{font-size: 36px;color: var(--color2);text-align: center;width: 90%;transition: 1s;}
.lilCard2:hover{border:.01px solid var(--color1);background: var(--color1);transition: .3s!important;}
.lilCard2:hover h3{transition:.3s;color: #FFF;}
.lilCard2:hover p{transition:.3s;color: #FFF;}
.lilCard2:hover .pattern{transition:.3s;color: #FFF;}
.lilCard2:hover .iconN{transition:.3s;background: #FFF;}
.lilCard2:hover .iconN i{color: var(--color1);transition: .3s;}
@media(max-width:1400px){
    nav::before{width:calc(65vw + 50px)}
    nav::after{width:65vw}}
@media(max-width:1250px){
    .activities .content .desc .illu {height: auto;align-items: center;}
    .activities .content .desc .illu img {margin: 20px 0;width: 100%;}
    .activities .content .desc .illu .thun::after {bottom: 30px;left: 10px;}
    #study .content .desc .illu {height: auto;align-items: center;width: 100%;}
    #study .content .desc .illu img {margin: 20px 0;width: 100%;}
    #study .content .desc .illu .thun::after {bottom: 30px;left: 10px;}
    .values .content {flex-direction: column;}
    .values .content .lilCard {width: 100%;margin: 30px 0;height: auto;}
    .values .content .lilCard h1 {width: 80%;font-size: 8vw;}
    .values .content .lilCard .iconN {transform: scale(0.8);}}
@media(max-width:1120px){
    nav::before{width:calc(70vw + 50px)}
    nav::after{width:70vw}
    .sectionTitle .decoration::before, .sectionTitle .decoration::after {width: 35%;}
    .contact .screen {width:95vw}
    #home1 .bg{overflow: hidden;}
    #home1 .bg img{width: auto;height: 100vh;}
}
@media(max-width:1000px){
    nav::before,nav::after{display: none;}
    nav .menuToggler{display: block;}
    nav .tools, nav .headings{display: none}
    nav .menuBar{display: flex;}
    nav .menuBar{width: 100vw;}
    .topBanner .cHeading h1 {font-size: 5vw;}
    .topBanner{background-size: auto 100%;}
    .whoAreWe .content {flex-direction: column;height: auto;}
    .whoAreWe .content .thumb ,.whoAreWe .content .desc {width: 100%;}
    .whoAreWe .content .thumb{margin:40px 0}
    .whoAreWe .content .desc{padding:40px 20px 0px 20px}
    .advice .content .desc2 {flex-direction: column;}
    .advice .content .desc1 {width: 90%;font-size: 18px;}
    .activities .content .desc {margin-left:0;padding: 0 10px;border-left: none;width: 100%}
    .activities .content .titleNum {justify-content: space-around}
    .activities .content .titleNum h1 {width: 70%}
    #study{overflow-x: hidden;}
    #study .content .desc {margin-left:0;padding: 0 10px;border-left: none;width: 100%}
    #study .content .titleNum {justify-content: space-around}
    #study .content .titleNum h1 {width: 70%}
    footer{flex-direction: column;display: flex;align-items: stretch;padding: 20px;}
    footer .footer-left,
    footer .footer-center,
    footer .footer-right{width: 100%;margin: 20px 0;}
    footer .footer-links,
    footer .footer-company-name {text-align: center;}
    footer .footer-center p,.contact .screen .content,.contact p{width: 80%;}
    .contact .screen .decor {width: 30%;}
    .contact .screen .content .bottomC .cRow h3{font-size: 22px;}
    .contact .screen .content .topC h2 {font-size: 25px;}
    #opc .content{flex-direction: column;}
    #opc .content .thumb, #opc .content .desc{width: 95%;}
    #opc .content .desc{margin: 0 auto;padding: 30px;}
    #opc .content .desc ul li {font-size: 20px;margin: 15px 0;}
    .whyWe{overflow: hidden;}
    .whyWe .content{flex-direction: column-reverse;}
    .whyWe .content p {width: 90%;margin-top: 35px;}
    .whyWe .content img {width: 90%;height: auto;}
    .buttonCenterX a {scale: 1;}
    .sectionTitle1 {width: 85%}
    .sectionTitle1::before,.sectionTitle1::after{width: 20px;height: 20px;top: 110%;}}
@media(max-width:850px){
    .buttonCenterX{flex-direction: column;}
    .buttonCenterX a:first-child{margin-bottom: 40px;}

}
@media(max-width:600px){
    .topBanner{background-size: 100% auto; -webkit-background-size: auto; -moz-background-size: auto 100%;background-position: 50% 0;-webkit-background-attachement:fixed}
    .topBanner .cHeading h1 {font-size: 7vw;}
    .serviceCard {width: 95%;margin: 10px auto;}
    .contact .screen .decor {display: none;}
    .contact .screen .content  {width: 100%;}
    .contact p{width: 95%;}
    #home1 .content h1 span,
    #home1 .content h1 {font-size: 8vw;}
    #home1 .content {left: 30px;width: 90%;}
    #home1 .bg {
        display: flex;
        justify-content: center;}
        .lilCard2 .topCard h3 {width: 70%;}
}